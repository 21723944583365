import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { ref: "home" }
const _hoisted_2 = { class: "grid grid-cols-3 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MovieItem = _resolveComponent("MovieItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(15, (index) => {
        return _createVNode(_component_MovieItem, { key: index })
      }), 64))
    ])
  ], 512))
}