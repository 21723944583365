<template>
	<div>
		<router-link v-if="movie" :to="/peliculas/ + movie.slug" class="movieItem">
			<div class="poster" :style="{ backgroundImage: 'url(' + movie.poster.data.thumbnails[5].url+ ')' }">
				<div class="play" v-if="showPlay">
					<div class="playIcon"></div>
				</div>
				<div v-if="movie.estreno" class="estreno">
					Estreno
				</div>
			</div>
			<h3 v-if="showTitle">{{ trimTitle(movie.titulo_local) }}</h3>
			<span class="date-tag" v-if="showDateTag">{{movie.fecha_local}}</span>
		</router-link>

		<div v-else class="movieItem">
			<div class="poster loading">
			</div>
			<h3 class="loading" v-if="showTitle"></h3>
			<span class="date-tag loading" v-if="showDateTag"></span>
		</div> 
	</div>               
</template>

<script>
	export default {
		name: 'MovieItem',
		
		data(){
			return{
				server: 'http://nuevo.enelcine.com.ar/api/public/api',
			}
		},

		props: {
			showPlay: {
				type: Boolean
			},
			showTitle: {
				type: Boolean
			},
			showDateTag: {
				type: Boolean
			},
			movie: Object
		},

		methods: {
			trimTitle: function(title){
				if(title.length > 30){
					return title.substring(0, 30) + '...'
				}else{
					return title
				}
			}
		}
	}
</script>

<style scoped>
/*-------MEDIDA PREESTABLECIDA PARA POSTERS/ AFICHES---------*/

.movieItem{
	width: 100%;
	display: flex;
	flex-direction: column;   
	flex-shrink: 0;
	text-decoration: none;
	color: black;
}

.movieItem h3{
	height: 27px;
}

.poster{
	width: 100%;
	padding-top: 142.8%;
	background: #cfcfcf;
	border-radius: 4px;
	position: relative;
	background-size: cover;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.2)
}

.estreno{
	background: black;
	color: white;
	padding: 4px 6px;
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 12px;
	font-weight: bold;
	border-radius: 0 8px 0 0;
}

h3.loading{
	width: 100%;
	height: 25px;
}

@media (max-width: 768px){
	.movieItem h3{
			height: 40px;
	}
}
</style>
